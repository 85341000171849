<section class="parent-container">
  <div class="container">
    <div class="container-top">
      <div class="container-title">
        <mat-toolbar>
          <h1>Cellular Usage</h1>
          <div class="mock-data-toggle">
            <mat-slide-toggle
              [(ngModel)]="showMockData"
              (ngModelChange)="toggleMockData($event)"
              color="primary"
            >
              {{
                showMockData ? "Switch to Real Data" : "Switch to Sample Data"
              }}
            </mat-slide-toggle>
          </div>
          <span class="spacer"></span>
          <div class="title-right">
            <app-analytics-time-selector
              (selectedOptionChange)="onChildSelectionChange($event)"
            />
          </div>
        </mat-toolbar>
      </div>
    </div>

    <mat-grid-list
      cols="5"
      rowHeight="400px"
      gutterSize="20px"
      *ngIf="!aggregatedIsNoContent"
    >
      <mat-grid-tile colspan="1">
        <app-analytics-widget
          [isNoContent]="aggregatedIsNoContent"
          [loading]="aggregatedLoading"
          class="analytics-widget"
        >
          <app-analytics-apex-chart-bar
            *ngIf="!aggregatedIsNoContent && !aggregatedLoading"
            [chartName]="siteChartTitle"
            [data]="siteChartData"
            [categories]="siteChartCategories"
            [yTitle]="siteChartYtitle"
          ></app-analytics-apex-chart-bar>
        </app-analytics-widget>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <app-analytics-widget
          [isNoContent]="aggregatedIsNoContent"
          [loading]="aggregatedLoading"
          class="analytics-widget"
        >
          <app-data-consumption
            *ngIf="!aggregatedIsNoContent && !aggregatedLoading"
            [highestDataConsumption]="highestDataConsumption"
            [totalDataConsumption]="totalDataConsumption"
          ></app-data-consumption>
        </app-analytics-widget>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <app-analytics-widget
          [isNoContent]="aggregatedIsNoContent"
          [loading]="aggregatedLoading"
          class="analytics-widget"
        >
          <app-analytics-apex-chart-pie
            *ngIf="!aggregatedIsNoContent && !aggregatedLoading"
            [series]="timeOfDayBreakdownData"
            [labels]="timeOfDayBreakdownLabels"
            [title]="timeOfDayBreakdownTitle"
          />
        </app-analytics-widget>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <app-analytics-widget
          [isNoContent]="aggregatedIsNoContent"
          [loading]="aggregatedLoading"
          class="analytics-widget"
        >
          <app-analytics-apex-chart-bar
          *ngIf="!aggregatedIsNoContent && !aggregatedLoading"
          [chartName]="top5DevicesCategoriesTitle"
          [data]="top5DevicesData"
          [categories]="top5DevicesCategories"
          [yTitle]="top5DevicesCategoriesYtitle"
        ></app-analytics-apex-chart-bar>
        </app-analytics-widget>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <app-cellular-table
    [dataSource]="metricTableData"
    [loading]="tableLoading"
    [isNoContent]="tableIsNoContent"
  />

</section>
