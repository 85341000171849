import {
  LoadTickets,
  LoadTicketsFail,
  LoadTicketsSuccess,
} from "../../../store";
import { Ticket } from "src/app/models/ticket.model";
import { ChartService } from "./chart.service";
import { LineValuesKpi } from "../../types";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import moment from "moment";
import { ChartType } from "../../types/chart-type";

const STATES = Object.freeze(["Closed"]);
const TYPE = "SUPPORT";

@Injectable()
export class CaseHistoryByDateChartService extends ChartService<LineValuesKpi> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadTickets,
    failure: LoadTicketsFail,
    success: LoadTicketsSuccess,
  }).pipe(
    map(({ loading, data }) => {
      type DataValue = LineValuesKpi["value"];

      let maxDate: moment.MomentInput = moment();
      let minDate: moment.MomentInput = moment();

      const groupedByDate: Record<string, 0> = {};
      for (const ticket of data || []) {
        const momentDate = moment(ticket.CreatedDate);
        minDate = moment.min(minDate, momentDate);
        maxDate = moment.max(maxDate, momentDate);

        const key = momentDate.format("YYYY/MM/DD");
        groupedByDate[key] ??= 0;
        groupedByDate[key]++;
      }

      let cursorDate = moment(minDate).add(-1, "days");
      const dataValue: DataValue = Array.from(
        { length: moment(maxDate).diff(minDate, "days") },
        () => {
          cursorDate = cursorDate.add(1, "days");
          const label = cursorDate.format("YYYY/MM/DD");

          return {
            label: label,
            value: groupedByDate[label] || 0,
            hyperlink: "/tickets", // not clear if this is the correct link
          };
        }
      );

      return {
        ngClass: [],
        loading,
        data: {
          type: ChartType.LINE,
          id: "Case history by date",
          title: "Ticket History by Date",
          value: dataValue.filter((s) => s.value),
        } satisfies LineValuesKpi,
      };
    })
  );
}
