import {
  LoadTickets,
  LoadTicketsFail,
  LoadTicketsSuccess,
} from "../../../store";
import { Ticket } from "src/app/models/ticket.model";
import { ChartService } from "./chart.service";
import { HorizontalBarsKpi } from "../../types";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ChartType } from "../../types/chart-type";

const STATES = Object.freeze(["Closed"]);
const TYPE = "SUPPORT";

@Injectable()
export class YtdNocCasesClosedCodeChartService extends ChartService<HorizontalBarsKpi> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadTickets,
    failure: LoadTicketsFail,
    success: LoadTicketsSuccess,
  }).pipe(
    map(({ loading, data }) => {
      type DataValue = HorizontalBarsKpi["value"];

      const dataValue: DataValue = Object.values(
        (data || []).reduce(
          (response: Record<string, DataValue[0]>, ticket) => {
            if (!this.filterByStatus(ticket)) return response;
            const ticketType = ticket.Portal_Ticket_Type__c;

            (response[ticketType] ??= {
              label: ticketType || '(Other)',
              value: 0,
            }).value += 1;

            return response;
          },
          {} as Record<string, DataValue[0]>
        )
      );

      return {
        ngClass: [],
        loading,
        data: {
          type: ChartType.BARSH,
          id: "YTD NOC cases closed code",
          title: "YTD Closed NOC Tickets by Code",
          value: dataValue,
          hyperlink: "/tickets", // need flags
        } satisfies HorizontalBarsKpi,
      };
    })
  );

  private filterByStatus(ticket: Ticket): boolean {
    return true;
    const ticketType = ticket.Business_Purpose__c?.toUpperCase();
    return STATES.includes(ticket.Status) && ticketType?.includes(TYPE);
  }
}
