<div class="container-table">
  <div class="container-top">
    <div class="container-title">
      <mat-toolbar>
        <span class="spacer"></span>
        <button mat-raised-button (click)="clearFilter()">Clear Filters</button>
        <button mat-raised-button color="primary" (click)="toggleFilters()">
          {{ showFilters ? "Hide Filters" : "Show Filters" }}
        </button>
      </mat-toolbar>
    </div>
  </div>

  <section class="table-unm">
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="edgeName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Edge Name</th>
          <td mat-cell *matCellDef="let product">{{ product.edgeName }}</td>
        </ng-container>

        <ng-container matColumnDef="edgeNameFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'edgeName')"
              placeholder="Filter Edge Name"
              class="table-input"
              [(ngModel)]="filterValues.edgeName"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="edgeNameFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <!-- Links Column -->
        <ng-container matColumnDef="sourceName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Source</th>
          <td mat-cell *matCellDef="let product">
            {{ product.source }}
          </td>
        </ng-container>

        <ng-container matColumnDef="sourceNameFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'sourceName')"
              placeholder="Filter Links"
              class="table-input"
              [(ngModel)]="filterValues.sourceName"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="sourceNameFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <ng-container matColumnDef="timestamp">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Timestamp</th>
          <td mat-cell *matCellDef="let product">{{ product.timestamp }}</td>
        </ng-container>

        <ng-container matColumnDef="timestampFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <div class="date-filter-container">
              <!-- Start Date Picker -->
              <input
                matInput
                [matDatepicker]="startDatePicker"
                [(ngModel)]="filterValues.startDate"
                (dateChange)="applyDateFilter()"
                placeholder="Start Date"
                class="table-input"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startDatePicker"
                class="date-picker-button"
              />
              <mat-datepicker #startDatePicker />

              <!-- Start Time Picker -->
              <input
                matInput
                [ngxMatTimepicker]="startTimePicker"
                [(ngModel)]="filterValues.startTime"
                placeholder="Start Time"
                class="table-input"
                (ngModelChange)="applyDateFilter()"
              />
              <ngx-mat-timepicker-toggle [for]="startTimePicker" matSuffix />
              <ngx-mat-timepicker #startTimePicker />
            </div>
          </th>
        </ng-container>
        <ng-container matColumnDef="timestampFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th">
            <div class="date-filter-container">
              <!-- End Date Picker -->
              <input
                matInput
                [matDatepicker]="endDatePicker"
                [(ngModel)]="filterValues.endDate"
                (dateChange)="applyDateFilter()"
                placeholder="End Date"
                class="table-input"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDatePicker"
                class="date-picker-button"
              ></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>

              <!-- End Time Picker -->
              <input
                matInput
                [ngxMatTimepicker]="endTimePicker"
                [(ngModel)]="filterValues.endTime"
                placeholder="End Time"
                class="table-input"
                (ngModelChange)="applyDateFilter()"
              />
              <ngx-mat-timepicker-toggle [for]="endTimePicker" matSuffix />
              <ngx-mat-timepicker #endTimePicker />
            </div>
          </th>
        </ng-container>

        <ng-container matColumnDef="carrier">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Carrier
          </th>
          <td mat-cell *matCellDef="let product">{{ product.carrier }}</td>
        </ng-container>

        <ng-container matColumnDef="carrierFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'carrier')"
              placeholder="Filter Carrier"
              class="table-input"
              [(ngModel)]="filterValues.carrier"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="carrierFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <ng-container matColumnDef="region">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Region
          </th>
          <td mat-cell *matCellDef="let product">{{ product.region }}</td>
        </ng-container>

        <ng-container matColumnDef="regionFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'region')"
              placeholder="Filter region"
              class="table-input"
              [(ngModel)]="filterValues.region"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="regionFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <ng-container matColumnDef="score">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>QOE Score </th>
          <td mat-cell *matCellDef="let product">{{ product.qos }}</td>
        </ng-container>

        <ng-container matColumnDef="scoreFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'qos')"
              placeholder="Filter score"
              class="table-input"
              [(ngModel)]="filterValues.qos"
            />
          </th>
        </ng-container>
        

        <ng-container matColumnDef="scoreFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            QOE Value
          </th>
          <td mat-cell *matCellDef="let product">{{ product.qos_category }}</td>
        </ng-container>

        <ng-container matColumnDef="valueFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'qos_category')"
              placeholder="Filter QoE Value"
              class="table-input"
              [(ngModel)]="filterValues.qos_category"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="valueFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <!-- Header and Row Declarations -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-header-row
          *matHeaderRowDef="filters"
          [ngClass]="{ 'hidden-row': !showFilters }"
        ></tr>
        <tr
          mat-header-row
          *matHeaderRowDef="filters2"
          [ngClass]="{ 'hidden-row': !showFilters }"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <tr *matNoDataRow [hidden]="loading">
            <td [attr.colspan]="displayedColumns.length" class="no-data-cell">
              <div class="no-content">No data available to display.</div>
            </td>
          </tr>
      </table>

      <div class="spinner-container" *ngIf="loading">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>

      <mat-paginator
        showFirstLastButtons
        [pageSize]="defaultPageSizeOption"
        [pageSizeOptions]="pageSizeOptions"
      ></mat-paginator>
    </div>
  </section>
</div>
