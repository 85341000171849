<header class="static-header" *ngIf="header.visible">
    <a routerLink="/">
      <div class="header-logo"></div>
    </a>
  
    <nav class="header-nav">
      <ul class="menu-items">
  
        <li class="nav-item" *ngFor="let item of nav">
          <ng-template [ngxPermissionsOnly]="item.permissions">
            <a
              *ngIf="!item.showOverlay"
              class="label"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{ exact: item.exact }"
              routerLink="{{ item.link }}"
              >{{ item.name }}</a
            >
          </ng-template>
        </li>
  
        <ng-template [ngxPermissionsOnly]="'Monitoring'">
          <li
            class="nav-item"
            *ngIf="networkUrl || hasMonitoringLink"
            [matMenuTriggerFor]="monitoringMenu"
          >
            <span>Monitoring</span>
          </li>
          <mat-menu #monitoringMenu="matMenu" xPosition="after">
            <a
              mat-menu-item
              *ngFor="let link of sortedMonitoringList"
              [attr.href]="link.URL__c"
              target="_blank"
              >{{ link.Name }}</a
            >
          </mat-menu>
        </ng-template>
  
        <ng-template [ngxPermissionsOnly]="'Nitel IQ Participant'">
          <li class="nav-item" [matMenuTriggerFor]="analyticsMenu">
            <a
              class="label"
              [ngClass]="{ 'active-link': isAnalyticsActive }"
              aria-label="Open Analytics menu"
              >Nitel IQ Analytics</a
            >
          </li>
          <mat-menu #analyticsMenu="matMenu" xPosition="after">
            <a
              mat-menu-item
              target="_self"
              [matMenuTriggerFor]="eventsAndLeadsMenu"
              aria-label="Navigate to Events & Leads"
              >Events & Leads</a
            >
            <a
              mat-menu-item
              class="label"
              routerLink="/metric-details"
              aria-label="Navigate to Metric Details"
              >Metric Details</a
            >
          </mat-menu>
          <mat-menu #eventsAndLeadsMenu="matMenu" xPosition="after">
            <a
              mat-menu-item
              class="label"
              routerLink="/unusual-network-metrics"
              aria-label="Navigate to Unusual Network Metrics"
              >Unusual Network Metrics</a
            >
            <a
            mat-menu-item
            class="label"
            routerLink="/cellular-usage"
            aria-label="Navigate to Cellular Usage"
            >Cellular Usage</a
            >
            <a
            mat-menu-item
            class="label"
            routerLink="/quality-of-experience"
            aria-label="Quality of Experience"
            >Quality of Experience</a
            >
          </mat-menu>
        </ng-template>
  
        <ng-container *ngFor="let item of nav2">
          <li
            class="nav-item"
            [matMenuTriggerFor]="item.children ? headerSubmenu : undefined"
          >
            <ng-template [ngxPermissionsOnly]="item.permissions">
              <a
                *ngIf="!item.showOverlay"
                routerLinkActive="active-link"
                [ngClass]="classForMenuItem(item)"
                [routerLinkActiveOptions]="{ exact: item.exact }"
                [routerLink]="item.link"
              >
                {{ item.name }}
              </a>
            </ng-template>
          </li>
          <mat-menu #headerSubmenu="matMenu" xPosition="after">
            <ng-container *ngFor="let child of item.children">
              <a mat-menu-item [routerLink]="child.link">
                {{ child.name }}
              </a>
            </ng-container>
          </mat-menu>
        </ng-container>
  
        <li class="nav-item logout" (click)="logOut()">
          <span class="label">Sign Out</span>
        </li>
  
        <app-account-menu></app-account-menu>
      </ul>
    </nav>
  </header>
  