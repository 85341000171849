import { LoadOrders, LoadOrdersFail, LoadOrdersSuccess } from "../../../store";
import { LineItem } from "src/app/models/line-item.model";
import { Order } from "src/app/models/order.model";
import { ChartType } from "../../types/chart-type";
import { ChartService } from "./chart.service";
import { SingleValueKPI } from "../../types";
import { Injectable } from "@angular/core";
import { map } from "rxjs";

@Injectable()
export class InflightOrdersChartService extends ChartService<SingleValueKPI> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadOrders,
    success: LoadOrdersSuccess,
    failure: LoadOrdersFail,
  }).pipe(
    map(({ loading, data }) => {
      const dataValue = (data || [])
        .filter((order) => this.filterByStatus(order))
        .reduce((count, order) => {
          const records = order.Customer_Order_OLI__r?.records || [];
          const hasValidRecord = records.some((record) =>
            this.filterOrderRecord(record)
          );

          if (hasValidRecord) count++;
          return count;
        }, 0);

      return {
        ngClass: [],
        loading,
        data: {
          type: ChartType.SINGLE,
          id: "Inflight Orders",
          title: "Inflight Orders",
          value: dataValue,
          hyperlink: "/orders", // need flags
        } satisfies SingleValueKPI,
      };
    })
  );

  private filterByStatus({ Status__c }: Order): boolean {
    return true || Status__c?.trim().toUpperCase() === "IN PROGRESS";
  }

  private filterOrderRecord({
    Line_Item_Type__c,
    RecordType,
    Service_Order2__r,
  }: LineItem): boolean {
    return RecordType.Name?.trim().toUpperCase() === "NEW";
  }
}
