import { Component, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatGridList } from '@angular/material/grid-list';
import { MatTableDataSource } from '@angular/material/table';
import { SynapseService } from 'src/app/services/synapse.service';
import { Subscription } from "rxjs";
import { Store } from '@ngrx/store';
import * as fromStore from 'src/app/store/';

interface Metrics {
  edgeName: string;
  links: string;
  timestamp: string;
  carrier: string;
  bandwidth: number;
}

@Component({
  selector: 'app-unm-page',
  templateUrl: './cellular-usage-page.component.html',
  styleUrls: ['./cellular-usage-page.component.scss'],
})
export class CellularUsagePageComponent implements OnDestroy {
  @ViewChild(MatGridList, { static: true }) matGridList!: MatGridList;

  accountName: string = '';
  selectedTime: string = '';
  showMockData = false; // Toggle between real and mock data

  aggregatedData: any[] = [];
  aggregatedIsNoContent = false;
  aggregatedLoading: boolean = false;

  tableData: any[] = [];
  tableIsNoContent = false; 
  tableLoading: boolean = false;

  // Data for site chart
  siteChartData: any[] = [];
  siteChartCategories: string[] = [];
  siteChartTitle: string = 'Site Data';
  siteChartYtitle: string = '(%)';

  // State variables
  highestDataConsumption: number = 0; 
  totalDataConsumption: number = 0; 

  // Data for time-of-day breakdown chart
  timeOfDayBreakdownData: number[] = []; 
  timeOfDayBreakdownLabels: string[] = ['12a - 8a', '8a - 4p', '4p - 12a'];
  timeOfDayBreakdownTitle: string = 'Time of Day Breakdown';

  // Data for the top 5 devices chart
  top5DevicesData: { name: string; data: number[] }[] = []; 
  top5DevicesCategories: string[] = [];
  top5DevicesCategoriesTitle: string = 'Top 5 Devices'; 
  top5DevicesCategoriesYtitle: string = 'Device Count'; 

  // Table data source
  metricTableData = new MatTableDataSource<Metrics>();

  // Subscriptions
  userSubscription: Subscription;
  chartsSubscription: Subscription;
  tableSubscription: Subscription;

  constructor(
    private synapseService: SynapseService,
    private cdr: ChangeDetectorRef,
    private store: Store<fromStore.State>
  ) { }

  // Cleanup logic to unsubscribe from observables when the component is destroyed
  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.chartsSubscription) {
      this.chartsSubscription.unsubscribe();
    }
    if (this.tableSubscription) {
      this.tableSubscription.unsubscribe();
    }
  }

  // Handle time selection change from a child component
  onChildSelectionChange(value: string): void {
    this.selectedTime = value;
    this.getUserInfoAndLoadData();
  }

  // Toggles between mock and real data
  toggleMockData(event: boolean): void {
    this.showMockData = event;
    this.getUserInfoAndLoadData(); // Reload data
  }

  // Retrieves user information and loads data
  getUserInfoAndLoadData(): void {
    if (this.accountName) {
      this.loadNitelIQData();
    }
    else {
      this.userSubscription = this.store.select(fromStore.getCurrentUser).subscribe({
        next: (data) => {
          this.accountName = data.Account?.Name;
          this.loadNitelIQData();
        },
        error: (err) => console.error('Error fetching user data:', err)
      });
    }
  }

  // Fetch data from the service
  loadNitelIQData(): void {

    // Reset table data and UI states
    this.metricTableData = new MatTableDataSource<Metrics>();

    this.aggregatedLoading = true;
    this.aggregatedIsNoContent = false;

    this.tableLoading = true;
    this.tableIsNoContent = false;

    this.highestDataConsumption = 0;
    this.totalDataConsumption = 0;

    this.chartsSubscription = this.synapseService.getAnomaliedLeadsAggregate(this.accountName, this.selectedTime, this.showMockData,).subscribe({
      next: (data) => {
        this.aggregatedData = data; 
        if (this.aggregatedData?.length) {
          this.updateMetrics(); 
          this.aggregatedIsNoContent = false;
        } else {
          this.aggregatedIsNoContent = true;
        }
        this.aggregatedLoading = false;
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.error('Error fetching metrics:', err);
        this.aggregatedIsNoContent = true;
        this.aggregatedLoading = false;
        this.cdr.detectChanges(); 
      }
    });

    this.tableSubscription = this.synapseService.getAnomaliedLeads(this.accountName, this.selectedTime, this.showMockData,).subscribe({
      next: (data) => {
        this.tableData = data;
        if (this.tableData?.length) {
          this.populateTableData();
          this.tableIsNoContent = false;
        } else {
          this.tableIsNoContent = true;
        }
        this.tableLoading = false;
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.error('Error fetching metrics:', err);
        this.tableIsNoContent = true;
        this.tableLoading = false;
        this.cdr.detectChanges();
      }
    });
  }

  // Update metrics, charts, and table data
  private updateMetrics(): void {
    
    this.calculateSiteData();
    this.calculateDataConsumption();
    this.calculateTimeOfDayBreakdown();
    this.calculateTop5Devices();
  }

  calculateDataConsumption(): void {
    let highestData = 0;
    let totalData = 0;

    this.aggregatedData.forEach((item) => {

      if (item.type === 'consumption') {
        item.details.forEach((chartValue) => {
          highestData = chartValue.highest_consumption;
          totalData = chartValue.total_consumption;
        });
      }

    });

    this.highestDataConsumption = highestData;
    this.totalDataConsumption = totalData;
  }

  calculateSiteData(): void {
    let chartData = [];
    let labels = [];

    this.aggregatedData.forEach((item) => {

      if (item.type === 'site') {
        item.details.forEach((chartValue) => {
          chartData.push(chartValue.percentage);
          labels.push(chartValue.status + ' - ' + chartValue.count);
        });
      }
      
    });
    this.siteChartData = [
      {
        name: 'Percentage',
        data: chartData
      }
    ];
    this.siteChartCategories = labels;
  }

  // Calculate time-of-day breakdown for metrics
  calculateTimeOfDayBreakdown(): void {
    let timeBuckets = [];
    let timeLabels = [];
    this.aggregatedData.forEach((item) => {

      if (item.type === 'breakdown') {
        item.details.forEach((chartValue) => {
          timeBuckets.push(chartValue.count);
          timeLabels.push(chartValue.interval);
        });
      }

    });
    this.timeOfDayBreakdownData = timeBuckets;
    this.timeOfDayBreakdownLabels = timeLabels;
  }

  calculateTop5Devices(): void {
    const deviceMap = new Map<string, number>();
    this.aggregatedData.forEach((item) => {

      if (item.type === 'device') {
        item.details.forEach((chartValue) => {
          deviceMap.set(chartValue.device, chartValue.count);
        });
      }

    });

    // Sort devices by count and take the top 5
    const sortedDevices = Array.from(deviceMap.entries())
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5);

    // Prepare data for chart
    this.top5DevicesData = [
      { name: 'Device Count', data: sortedDevices.map(([_, count]) => count) },
    ];
    this.top5DevicesCategories = sortedDevices.map(([name]) => name);
  }

  populateTableData(): void {
    const tableData = this.tableData.map((item) => {
      // Construct table row
      return {
        edgeName: item.device_name,
        links: item.link,
        timestamp: this.formatDate(item.time_frame), // Format timestamp
        carrier: item.carrier,
        bandwidth: item.bandwidth
      };
    });
    this.metricTableData.data = tableData; // Update table data source
  }

  // Format a timestamp into a readable string
  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    return `${date.toLocaleDateString('en-US')} ${date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    })}`;
  }
}