<section class="parent-container">
  <div class="container">
    <div class="container-top">
      <div class="container-title">
        <mat-toolbar>
          <h1>Unusual Network Metrics</h1>
          <div class="mock-data-toggle">
            <mat-slide-toggle
              [(ngModel)]="showMockData"
              (ngModelChange)="toggleMockData($event)"
              color="primary"
            >
              {{
                showMockData ? "Switch to Real Data" : "Switch to Sample Data"
              }}
            </mat-slide-toggle>
          </div>
          <span class="spacer"></span>
          <div class="title-right">
            <app-analytics-time-selector
              (selectedOptionChange)="onChildSelectionChange($event)"
            />
          </div>
        </mat-toolbar>
      </div>
    </div>

    <mat-grid-list
      cols="5"
      rowHeight="400px"
      gutterSize="20px"
      *ngIf="!isNoContent"
    >
      <mat-grid-tile colspan="1">
        <app-analytics-widget
          [isNoContent]="isNoContent"
          [loading]="loading"
          class="analytics-widget"
        >
          <app-analytics-apex-chart-pie
            *ngIf="!isNoContent && !loading"
            [series]="severityBreakdownData"
            [labels]="severityBreakdownLabels"
            [title]="severityBreakdownTitle"
            (categoryChange)="calculateCategoryMetrics($event)"
          />
        </app-analytics-widget>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <app-analytics-widget
          [isNoContent]="isNoContent"
          [loading]="loading"
          class="analytics-widget"
        >
          <app-highlighted-network-measurements
            *ngIf="!isNoContent && !loading"
            [category]="measurementCategory"
            [dataUtilization]="dataUtilization"
            [latency]="latency"
            [jitter]="jitter"
            [packetLost]="packetLost"
            class="analytics-widget"
          />
        </app-analytics-widget>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <app-analytics-widget
          [isNoContent]="isNoContent"
          [loading]="loading"
          class="analytics-widget"
        >
          <app-analytics-apex-chart-pie
            *ngIf="!isNoContent && !loading"
            [series]="timeOfDayBreakdownData"
            [labels]="timeOfDayBreakdownLabels"
            [title]="timeOfDayBreakdownTitle"
          />
        </app-analytics-widget>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <app-analytics-widget
          [isNoContent]="isNoContent"
          [loading]="loading"
          class="analytics-widget"
        >
          <app-analytics-apex-chart-bar
            *ngIf="!isNoContent && !loading"
            [chartName]="topUnusualDevicesTitle"
            [data]="topUnusualDevicesData"
            [categories]="topUnusualDevicesCategories"
            [yTitle]="topUnusualDevicesYtitle"
          ></app-analytics-apex-chart-bar>
        </app-analytics-widget>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <app-unm-data-table
    [dataSource]="unmTableData"
    [loading]="loading"
    [isNoContent]="isNoContent"
  />
</section>
