import { SurveyService } from "../../../services/survey.service";
import { inject, Injectable } from "@angular/core";
import { ChartService } from "./chart.service";
import { GaugeValueKPI } from "../../types";
import { map, startWith } from "rxjs";
import { ChartType } from "../../types/chart-type";

const DEFAULT_MAX_VALUE = 100;
const DEFAULT_MIN_VALUE = -100;

enum RTAStates {
  RED = DEFAULT_MIN_VALUE,
  YELLOW = -20,
  GREEN = 50,
}

const THRESHOLDS: GaugeValueKPI["thresholds"] = Object.freeze({
  [RTAStates.GREEN]: { color: "#0F0A", bgOpacity: 0.2 },
  [RTAStates.YELLOW]: { color: "#FD0A", bgOpacity: 0.2 },
  [RTAStates.RED]: { color: "#F00A", bgOpacity: 0.2 },
});

@Injectable()
export class YearNitelNpsChartService extends ChartService<GaugeValueKPI> {
  public override readonly chart$: ChartService<GaugeValueKPI>["chart$"];

  constructor() {
    super();
    const npsService = inject(SurveyService);

    const date = new Date();
    const observable = npsService
      .getYearNPS(date.getFullYear())
      .pipe(startWith({ score: null }));

    this.chart$ = observable.pipe(
      map(({ score }) => {
        return {
          loading: score === null,
          ngClass: [],
          data: {
            type: ChartType.GAUGE,
            id: "Nitel NPS",
            title: "Nitel NPS",
            numberLabel: "Score",
            value: parseFloat(((score || 0) * 10).toFixed(2)),
            // hyperlink: "/",
            minValue: DEFAULT_MIN_VALUE,
            maxValue: DEFAULT_MAX_VALUE,
            markers: this.makeMarkers((score || 0) * 10),
            thresholds: { ...THRESHOLDS },
          } satisfies GaugeValueKPI,
        };
      })
    );
  }

  private makeMarkers(value: number): GaugeValueKPI["markers"] {
    const defaults = Object.freeze({
      color: "#888",
      type: "line",
      size: 8,
    });

    return {
      [RTAStates.GREEN]: { ...defaults, label: `${RTAStates.GREEN}` },
      [RTAStates.YELLOW]: { ...defaults, label: `${RTAStates.YELLOW}` },
      [RTAStates.RED]: { ...defaults, label: `${RTAStates.RED}` },
      [DEFAULT_MAX_VALUE]: { ...defaults, label: `${DEFAULT_MAX_VALUE}` },
      [value]: {
        ...defaults,
        label: DEFAULT_MAX_VALUE < value ? `${value}` : "",
        type: "triangle",
        color: "#000",
      },
    };
  }
}
