import {
  LoadTickets,
  LoadTicketsFail,
  LoadTicketsSuccess,
} from "../../../store";
import { Ticket } from "src/app/models/ticket.model";
import { ChartService } from "./chart.service";
import { SingleValueKPI } from "../../types";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import moment from "moment";
import { ChartType } from "../../types/chart-type";

const DAYS_LIMIT = 30;

@Injectable()
export class NewClosedCasesChartService extends ChartService<SingleValueKPI> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadTickets,
    failure: LoadTicketsFail,
    success: LoadTicketsSuccess,
  }).pipe(
    map(({ loading, data }) => {
      const dataValue: number =
        data?.filter(
          (t) => this.filterByStatus(t) && this.oldAgeFilter(t.CreatedDate)
        ).length || 0;

      return {
        ngClass: [],
        loading,
        data: {
          type: ChartType.SINGLE,
          id: "Closed Cases < 30",
          title: "Closed Tickets (Last 30 Days)",
          value: dataValue,
          hyperlink: "", // TODO
        } satisfies SingleValueKPI,
      };
    })
  );

  private oldAgeFilter(inputDate: moment.MomentInput) {
    const currentDate = moment();
    const differenceInDays = currentDate.diff(inputDate, "days");
    return differenceInDays <= DAYS_LIMIT;
  }

  private filterByStatus({ IsClosed }: Ticket): boolean {
    return IsClosed;
  }
}
