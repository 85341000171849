import { DownloadChartsService } from "./services/download-chart/download-charts.service";
import { ChartsService } from "./services/charts-service";
import { Component, inject } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-operations-dashboard",
  templateUrl: "./operations-page.component.html",
  styleUrls: ["./operations-page.component.scss"],
  providers: [DownloadChartsService, ChartsService, ...ChartsService.providers],
})
export class OperationsComponent {
  private readonly router$ = inject(Router);
  private readonly downloadChartsService$ = inject(DownloadChartsService);
  public readonly charts$ = inject(ChartsService);
  public readonly loading: boolean = false;

  public dowloadFilesAsPdf(): void {
    this.downloadChartsService$.downloadCharts(this.charts$);
  }

  public handleNavigation(link: string): void {
    this.router$.navigate([link]);
  }
}
